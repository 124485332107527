body {
  /*background: #040404;*/
  background-color: #111111;
  margin: 0;
  padding: 0;
  font-family: "Heebo", sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #708396;*/
  color: #a7afc7;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a, h1, h2, h3, h4 {
  color: #fff;
}